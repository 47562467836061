<template>
    <div class="wrapper">
        <div class="header">
            <div v-if="hasDownloadAllPermission" class="generate">
                <div class="generate-filter" ref="filter">
                    <Dropdown
                        v-if="hasDownloadAllPermission"
                        :disabled="isGenerate || isUpdating"
                        name="contestId"
                        title="報表名稱"
                        :required="true"
                        placeholder="請選擇業績..."
                        :options="performanceList"
                        v-model="filterPayload.dataType"
                    />
                    <Textbox
                        v-if="hasDownloadAllPermission"
                        :disabled="isGenerate || isUpdating"
                        name="baseDate"
                        title="組織基準日"
                        type="date"
                        max="today"
                        min="2020/01/01"
                        :required="true"
                        v-model:modelValue="filterPayload.baseDate"
                    />
                    <DateRangeTextbox
                        v-if="
                            hasDownloadAllPermission &&
                            filterPayload.dataType === 2
                        "
                        :isDisabled="isGenerate || isUpdating"
                        startName="periodStart"
                        endName="periodEnd"
                        max="today"
                        min="default"
                        :required="true"
                        :hasLimit="true"
                        :limitDays="limitDays"
                        :isOnlyMonth="true"
                        title="查詢工作月"
                        v-model:startDate="filterPayload.periodStart"
                        v-model:endDate="filterPayload.periodEnd"
                    />
                    <DateRangeTextbox
                        v-if="
                            hasDownloadAllPermission &&
                            filterPayload.dataType === 1
                        "
                        :isDisabled="isGenerate || isUpdating"
                        startName="receiveDateStart"
                        endName="receiveDateEnd"
                        max="today"
                        min="default"
                        :required="true"
                        :hasLimit="true"
                        :limitDays="limitDays"
                        title="查詢受理日"
                        v-model:startDate="filterPayload.receiveDateStart"
                        v-model:endDate="filterPayload.receiveDateEnd"
                    />
                </div>
                <div v-if="hasDownloadAllPermission" class="generate-update">
                    <p v-if="isGenerate || isUpdating" class="reminder">
                        有業績報表正在生產中，請稍候更新報表狀態
                    </p>
                    <Button
                        v-if="hasDownloadAllPermission"
                        :disabled="isGenerate || isUpdating"
                        :buttonWord="buttonText"
                        buttonStyle="blue-hollow"
                        :iconClass="buttonIcon"
                        @click="generateReport"
                    />
                </div>
            </div>
            <div v-if="hasDownloadAllPermission" class="header-info">
                <div class="describe">最後更新時間：{{ currentTime }}</div>
                <Button
                    @click="updatedReport"
                    class="header-info-button"
                    buttonWord="更新報表狀態"
                    buttonStyle="blue"
                />
            </div>
            <div v-if="!hasDownloadAllPermission" class="header-updated">
                <p class="header-updated_text">
                    資料每4小時更新一次，建議下載前先更新檔案。
                    若檔案仍未更新至最新時間，請通知相關同仁協助產檔。
                </p>
                <Button
                    @click="updatedReport"
                    buttonWord="更新報表"
                    buttonStyle="blue"
                    class=""
                />
            </div>
        </div>
        <div class="table">
            <Table
                :column-config="performanceColumnConfig"
                :rows="sortedReport"
                :isLoading="isTableLoading || isUpdating"
            >
                <template v-slot:reportLink="{ isHeader, row }">
                    <div
                        v-if="!isHeader"
                        :class="[
                            'download-content-icon',
                            { disabled: row.status }
                        ]"
                        @click="downloadReport(row)"
                    ></div>
                </template>
                <template v-slot:status="{ isHeader, row }">
                    <div v-if="!isHeader" :class="{ generating: row.status }">
                        {{ row.status ? row.status : '-' }}
                    </div>
                </template>
            </Table>
        </div>
        <DownloadModal
            title="業績報表下載"
            modal-size="615px"
            v-model:action="action"
        />
    </div>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue'
import DateRangeTextbox from '@/components/DateRangeTextbox.vue'
import Button from '@/components/Button.vue'
import DownloadModal from '@/containers/backstage/DownloadModal.vue'
import Textbox from '@/components/Textbox.vue'
import Table from '@/components/Table.vue'
import { performanceDownloadColumn } from '../../assets/javascripts/backstage/setting'
import {
    getBackstagePerformanceReportInfosAPI,
    getBackstageDownloadPerformanceReportAPI,
    postBackstageGeneratePerformanceReportAPI,
    getPerformanceCodesAPI
} from '@/assets/javascripts/api.js'

export default {
    name: 'PerformanceDownload',
    components: {
        Dropdown,
        DateRangeTextbox,
        Button,
        Table,
        DownloadModal,
        Textbox
    },
    async mounted() {
        this.$showLoading()
        await this.getPerformanceList()
        await this.updatedReport()
        await this.detectState()
        this.$hideLoading()
    },
    data() {
        return {
            buttonText: '生產報表',
            buttonIcon: 'generate-excel-icon',
            performanceList: [],
            filterPayload: {
                dataType: 1,
                periodStart: null,
                periodEnd: null,
                receiveDateStart: null,
                receiveDateEnd: null,
                baseDate: null
            },
            limitDays: {
                year: 1,
                month: 0
            },
            performanceColumnConfig: performanceDownloadColumn,
            action: null,
            reportList: [],
            isUpdating: false, //報表是否更新中
            isTableLoading: false,
            isGenerate: false, //判斷生產 api 是否還在執行
            isAllInput: false, //判斷所有欄位是否輸入的狀態
            currentTime: null
        }
    },
    methods: {
        getSortedReport: function (data) {
            return data.sort((a, b) => {
                return b.id - a.id
            })
        },
        getPerformanceList: async function () {
            try {
                let response = await getPerformanceCodesAPI()
                this.performanceList = response.data.dataType
                    .slice(0, 2)
                    .map((item) => {
                        return {
                            label: `${item.value}業績報表`,
                            value: item.key
                        }
                    })
            } catch (error) {
                console.log('error')
            }
        },
        transformDate: function (date) {
            let selectedDate = new Date(date)
            let year = selectedDate.getFullYear()
            let month = selectedDate.getMonth() + 1
            month = month.toString().length < 2 ? `0${month}` : month
            return `${year}${month}`
        },
        generateReport: async function () {
            this.detectInput()
            if (!this.isAllInput || this.isUpdating) return
            try {
                this.isUpdating = true
                let transformedPayload = { ...this.filterPayload }
                if (transformedPayload.dataType === 1) {
                    delete transformedPayload.periodEnd
                    delete transformedPayload.periodStart
                } else {
                    delete transformedPayload.receiveDateEnd
                    delete transformedPayload.receiveDateStart
                    //將 payload 日期內容轉換成後端指定的格式
                    transformedPayload.periodStart = this.transformDate(
                        transformedPayload.periodStart
                    )
                    transformedPayload.periodEnd = this.transformDate(
                        transformedPayload.periodEnd
                    )
                }
                let response = await postBackstageGeneratePerformanceReportAPI(
                    transformedPayload
                )
                this.action = 'success'
                if (response.data.msg === 'success') {
                    //因為後端資料 status 更新機制有延遲，需多幾秒才能保證取得更新狀態
                    this.detectState()
                    setTimeout(async () => {
                        await this.updatedReport()
                        this.isUpdating = false
                        this.action = null
                    }, 3000)
                }
            } catch (error) {
                this.action = 'generate-failure'
                this.isUpdating = false
            }
        },
        downloadReport: async function (row) {
            if (row.status) return
            try {
                let response = await getBackstageDownloadPerformanceReportAPI(
                    row.id
                )
                if (!response.data?.urls?.length) throw new Error()
                let aLink = document.createElement('a')
                aLink.href = response.data.urls[0]
                //為了解決同頁面跳轉行為被監聽問題 *//
                aLink.setAttribute('target', '_blank')
                aLink.click()
            } catch (error) {
                this.action = 'download-failure'
            }
        },
        updatedReport: async function () {
            if (this.isTableLoading) return
            try {
                this.isTableLoading = true
                let response = await getBackstagePerformanceReportInfosAPI(
                    this.filterPayload
                )
                this.reportList = response.data.excelInfos
                this.detectState()
            } catch (error) {
                this.action = 'updated-failure'
            } finally {
                this.isTableLoading = false
            }
        },
        detectInput: function () {
            // 因為 filterPayload 是所有值的集合，後續操作會將不需要的值刪除，所以先複製一份
            let transformedPayload = { ...this.filterPayload }

            if (this.filterPayload.dataType === 1) {
                delete transformedPayload.periodEnd
                delete transformedPayload.periodStart
            } else {
                delete transformedPayload.receiveDateEnd
                delete transformedPayload.receiveDateStart
            }
            //檢查篩選欄位是否有填寫
            Object.keys(transformedPayload).forEach((column) => {
                if (!transformedPayload[column] && column !== 'dataType') {
                    let emptyColumnNode = this.$refs.filter.querySelector(
                        `[name="${column}"]`
                    )
                    let parentNode = emptyColumnNode.parentElement
                    if (
                        !parentNode.classList.contains('input-error-ci-style')
                    ) {
                        parentNode.classList.toggle('input-error-ci-style')
                    }
                }
            })
            //檢查是否所有欄位都有填寫，如果有的話將 isAllInput 設為 true
            this.isAllInput = Object.values(transformedPayload).every(
                (value) => Boolean(value) === true
            )
        },
        detectState: function () {
            //status 代表報表的生產狀態: '生產中' / null
            this.isGenerate = !this.reportList.every(
                (report) => report.status === null
            )
        }
    },
    computed: {
        sortedReport: function () {
            return this.getSortedReport(this.reportList)
        },
        hasDownloadAllPermission: function () {
            let currentRouteName = 'BackstageReportPerformance'
            return this.$store.state.permissions[currentRouteName].includes(
                'generate'
            )
        }
    },
    watch: {
        sortedReport: {
            handler() {
                let date = this.$getDate()
                let currentDate = this.$formatDate(date, '.')
                let currentHour = new Date().getHours()
                let currentMin = new Date().getMinutes()
                let currentSeconds = new Date().getSeconds()
                //個位數分鐘加上0
                if (currentMin.toString().length < 2)
                    currentMin = '0' + currentMin
                if (currentSeconds.toString().length < 2)
                    currentSeconds = '0' + currentSeconds
                this.currentTime = `${currentDate} ${currentHour}:${currentMin}:${currentSeconds}`
            }
        }
    }
}
</script>
<style lang="scss" scoped>
:deep(.table) {
    @media screen and(max-width:576px) {
        padding: 0;
    }
}
:deep(.default-button) {
    max-width: 160px;
    width: 124px;
    @media screen and(max-width:576px) {
        width: auto;
        max-width: none;
    }
}
:deep(.report-link) {
    .download-content-icon {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
}
:deep(.status) {
    .generating {
        color: $fourth-blue;
    }
}

//----修改共用元件處 ----
.wrapper {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        @media screen and(max-width:576px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 0 20px 0;
            margin-bottom: 0;
        }
        .generate {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            width: 100%;

            border-bottom: 1px solid #d9d9d9d9;
            margin-bottom: 15px;
            &-filter {
                display: grid;
                display: -ms-grid;
                grid-template-columns: repeat(auto-fill, 210px);
                -ms-grid-columns: repeat(auto-fill, 210px);
                justify-content: space-between;
                gap: 0 20px;
                width: 100%;

                @media screen and (max-width: 576px) {
                    grid-template-columns: 100%;
                    :deep(.input-container) {
                        width: 100%;
                    }
                }
            }
            &-update {
                display: flex;
                align-self: flex-end;
                gap: 10px;
                margin-bottom: 15px; //與border 的距離
                @media screen and(max-width:576px) {
                    width: 100%;
                    justify-content: flex-start;
                    flex-direction: column;
                }
                .reminder {
                    margin: 0; //remove p-tag default
                    color: $fourth-red;
                    font-size: 12px;
                    align-self: flex-end;
                    @media screen and(max-width:576px) {
                        align-self: flex-start;
                        order: 1;
                    }
                }
                &-button {
                    position: relative;
                    //與其他欄位對齊
                    margin-top: 20px;
                    margin-left: auto;
                    @media screen and(max-width:768px) {
                        min-width: 80px;
                    }
                    @media screen and(max-width:576px) {
                        min-width: 100px;
                        margin-top: 0;
                    }
                }
            }

            .updated-button {
                //與其他欄位對齊
                margin-top: 20px;
                margin-left: auto;
                @media screen and(max-width:768px) {
                    min-width: 80px;
                }
                @media screen and(max-width:576px) {
                    margin-top: 0;
                }
            }
        }
        &-info {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            gap: 10px;
            .describe {
                font-size: 12px;
                @media screen and(max-width:576px) {
                    padding: 0;
                }
            }
            &-button {
                @media screen and(max-width:576px) {
                    width: 100px;
                }
            }
        }
        &-updated {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            margin-left: auto;
            gap: 10px;

            &_text {
                color: #333333;
                margin-left: auto;
                max-width: 300px;
                text-indent: 30px;
                text-align: flex-end;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}
</style>
